// Hilton Palette
// DO NOT USE DIRECTLY IN LESS, REFERANCE IN BRANDING SECTION
@primary-cyan: rgb(0, 168, 225);
@secondary-darkgray: rgb(30, 30, 30);
@secondary-orange: rgb(239, 118, 34);
@secondary-pink: rgb(206, 50, 98);
@secondary-burgundy: rgb(132, 35, 94);
@secondary-white: rgb(255, 255, 255);
@secondary-lightgray: rgb(147, 149, 152);
@secondary-darkblue: rgb(0, 40, 86);
@tertiary-silver: rgb(224, 222, 216);
@tertiary-gold: rgb(138, 117, 86);
// Branding
@title-primary: @primary-cyan;
@title-secondary: @secondary-darkblue;
@tag-line-background: @primary-cyan;
@tag-line-text: #fff;
@call-out-dynamic-graph: @secondary-pink;
@call-out-size: 180px;
@data-selections-label: #aaa;
@data-selections-underline: #ccc;
@summary-background: @secondary-darkblue;
@summary-text: #fff;
@form-error: #a94442;
@btn-dark: @primary-cyan;
@graph-default-line: @primary-cyan;
@graph-dynamic-line: #85ce8c;
@graph-bar-line: #ccc;
@graph-axis-lines: #000;
@graph-no-availability-bar: #f5f5f5;
@validations-bar-background: @secondary-darkgray;

.screensize {
  width: 1920px;
  height: 1080px;
}

.max-screensize {
  max-width: 1920px;
}

hr {
  margin-top: 0;
  border-top: 1px solid #ddd;
}

body {
  color: #666;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  &.loading {
    #loading-container {
      display: block;
      background: #ffffff;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9999;

      span {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, 50%);
      }
    }
  }
}

#loading-container {
  display: none;
}

.log-out-link {
  position: absolute;
  top: 15px;
  right: 15px;
}

label {
  font-weight: 400;
}

.label-explanation {
  font-size: 0.75em;
  margin-bottom: 10px;
  line-height: 1.2em;
}

.label-thin {
  line-height: 1em;
  margin-bottom: 0;
}

.brand-name {
  font-size: 24px;
  font-weight: bold;
}

.branding {
  margin-top: 10px;
  .subtitle {
    line-height: 1em;
    text-align: center;
    font-size: 22px;
    margin-left: -46px;
    color: @title-primary;
  }
  .title {
    line-height: 1em;
    text-align: center;
    font-size: 51px;
    letter-spacing: -1px;
    .primary {
      white-space: nowrap;
      color: @title-primary;
      font-weight: 300;
      padding: 0 1px;
    }
    .secondary {
      white-space: nowrap;
      color: @title-secondary;
    }
  }
}

.page-header {
  padding-bottom: 16px;
}

.main-nav {
  padding-top: 10px;
  .nav-tabs li:first-of-type {
    margin-left: 45px;
  }
}

.top-section {
  padding: 30px 40px 0;
  .top-row {
    margin-bottom: 20px;
  }
  .logo {
    padding-left: 55px;
  }
  .data-selections {
    padding-right: 70px;
    padding-top: 20px;
  }
}

.page-admin .admin-section {
  padding-top: 15px;
  .top-section {
    padding-top: 0;
    padding-bottom: 10px;
    .branding {
      margin-top: 0;
      .title {
        font-size: 34px;
      }
      .subtitle {
        margin-left: -13px;
        .primary {
          font-size: 17px;
        }
      }
    }
  }
}

.data-selections {
  .left-side .underline {
    min-width: 250px;
    label {
      min-width: 50px;
    }
  }
  .right-side {
    padding-left: 25px;
    .underline {
      min-width: 130px;
      label {
        min-width: 80px;
      }
    }
  }
  .data-selection-box {
    min-height: 52px;
    padding: 6px;
  }
  .underline {
    display: inline-block;
    border-bottom: 1px solid @data-selections-underline;
    margin-left: 10px;
    label {
      color: @data-selections-label;
    }
  }
}

.tag-line {
  text-transform: capitalize;
  color: @tag-line-text;
  background-color: @tag-line-background;
  margin: 0 25px 30px;
  padding: 10px 15px;
  h1,
  h2 {
    margin: 0;
    line-height: 1em;
    vertical-align: middle;
  }
  h1 {
    font-size: 30px;
    text-transform: uppercase;
    margin-bottom: 0.2em;
  }
  h2 {
    font-size: 20px;
  }
}

.admin-section {
  padding: 20px 0;
}

.admin-section {
  .title-section {
    font-size: 24px;
    margin-top: 30px;
  }
  .logo {
    padding-bottom: 10px;
  }
}

#day-selection {
  padding-bottom: 20px;
}

#static-rate-selection {
  padding-bottom: 40px;
}

.block.bottom-padding {
  padding-bottom: 14px;
}

.block.top-padding {
  padding-top: 10px;
}

.button-bar {
  padding-top: 40px;
  padding-bottom: 10px;
}

.pdf-validation-message {
  display: inline-block;
  vertical-align: middle;
  padding-left: 10px;
  color: @form-error;
}

#generate-pdf.disabled {
  background-color: #666;
  border-color: #555;
}

.loading-spinner {
  display: none;
  img {
    padding-right: 5px;
  }
  .info {
    color: @title-primary;
  }
}

.loading-spinner.active {
  display: flex;
  align-items: center;
}

.graph-container {
  margin: 30px 0;
  min-height: 360px;
  position: relative;
}

.graph-legend {
  right: 130px;
  top: -30px;
  position: absolute;
  li {
    display: inline-block;
    padding: 0px 5px;
    border-left-width: 20px;
    border-left-style: solid;
    &.dcp {
      border-color: @graph-dynamic-line;
    }
    &.bar {
      border-color: @graph-bar-line;
    }
    &.static {
      border-color: @graph-default-line;
    }
    &.no-availability {
      border-color: @graph-no-availability-bar;
    }
  }
}

.btn.btn-dark {
  color: #fff;
  background-color: @btn-dark;
  border-color: @btn-dark;
  &:hover,
  &:active,
  &:focus {
    color: #fff;
    background-color: darken(@btn-dark, 10);
    border-color: darken(@btn-dark, 10);
  }
}

.y.axis,
.room-type-selection {
  text-transform: capitalize;
}

.call-out {
  text-align: center;
  font-size: 20px;
  width: @call-out-size;
  margin-left: 40px;
  .top-text {
    /* Height must be fixed incase there is no text */
    line-height: 1.2em;
    height: 2.4em;
    margin-bottom: 10px;
  }
  .circle {
    color: #fff;
    position: relative;
    height: @call-out-size;
    width: @call-out-size;
    border-radius: 50%;
    font-weight: 700;
    .hide-btn {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.3s, opacity 0.3s linear;
    }
  }
  &:hover {
    .circle .hide-btn {
      visibility: visible;
      opacity: 1;
      transition: visibility 0.3s, opacity 0.3s linear;
    }
  }
  &.exclude-from-pdf {
    opacity: 0.5;
    .hide-btn {
      .glyphicon-ok {
        display: inline-block;
      }
      .glyphicon-remove {
        display: none;
      }
    }
  }
  .hide-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    font-size: 30px;
    line-height: 50px;
    border-radius: 50%;
    text-align: center;
    &:hover {
      cursor: pointer;
    }
    .glyphicon-ok {
      display: none;
    }
  }
}

.dynamic-graph-footer .call-out .circle {
  background-color: @call-out-dynamic-graph;
  .hide-btn {
    background-color: @call-out-dynamic-graph;
    border: 4px solid darken(@call-out-dynamic-graph, 20%);
  }
}

.days-call-out {
  .circle-main {
    padding-top: 20px;
    font-size: 90px;
    line-height: 1em;
  }
  .circle-bottom {
    text-transform: uppercase;
    font-size: 28px;
    line-height: 1em;
  }
}

.monetary-call-out {
  .circle-main {
    line-height: 1em;
    padding-top: 30px;
    font-size: 90px;
    &.text-length-4 {
      padding-top: 45px;
      font-size: 80px;
    }
    &.text-length-5 {
      padding-top: 55px;
      font-size: 70px;
    }
    &.text-length-6 {
      padding-top: 60px;
      font-size: 60px;
    }
    &.text-length-7 {
      padding-top: 70px;
      font-size: 50px;
    }
    &.text-length-8 {
      padding-top: 70px;
      font-size: 45px;
    }
  }
  .circle-bottom {
    text-transform: uppercase;
    font-size: 40px;
    line-height: 1em;
  }
}

.percent-call-out {
  .circle-main {
    padding-top: 55px;
    font-size: 75px;
    line-height: 1em;
  }
  .circle-bottom {
    font-size: 80px;
    line-height: 1em;
  }
}

.page-admin {
  padding: 0 35px;
}

#validation-errors {
  display: none;
  color: #fff;
  background-color: @title-secondary;
  padding: 15px 30px;
}

footer {
  margin-top: 40px;
  margin-bottom: 15px;
  .logo-hilton {
    margin-left: 65px;
  }
  .banners img {
    margin-left: 15px;
  }
  .charts {
    margin-right: 75px;
  }
  .bottom-banner-text {
    margin-top: 10px;
    font-size: 14px;
    color: #000;
  }
  &.summary-footer {
    margin-top: 35px;
    .page-footer {
      margin-top: 40px;
    }
  }
  .page-footer {
    margin-top: 25px;
    padding-right: 15px;
    padding-left: 15px;
    font-size: 12px;
    .bottom-line {
      font-weight: 700;
    }
    .copyright {
      text-align: left;
    }
    .created-date {
      text-align: right;
    }
  }
}

/* Exclude items from phantom render */

html[data-useragent*='PhantomJS'] {
  .exclude-from-pdf {
    display: none;
  }
}

/* Graph style */

svg {
  .axis line {
    fill: none;
    stroke: @graph-axis-lines;
    shape-rendering: crispEdges;
  }
  .axis line {
    stroke-opacity: 0.2;
  }
  .x.axis path {
    display: none;
  }
  .line {
    fill: none;
    stroke: @graph-default-line;
    stroke-width: 3.5px;
  }
  .no-availability {
    stroke: transparent;
  }
  .barline {
    fill: none;
    stroke: @graph-bar-line;
    stroke-width: 3.5px;
  }
  .dynamicline {
    fill: none;
    stroke: @graph-dynamic-line;
    stroke-width: 3.5px;
  }
  .static-price-label {
    fill: @graph-default-line;
    font-weight: bold;
  }
  .no-availability-bar {
    fill: @graph-no-availability-bar;
  }
}


/* Error display */

.error-icon,
.error-message {
  color: @form-error;
  display: none;
}

.error-message {
  font-size: 0.75em;
  text-align: right;
  width: 100%;
}

.has-error {
  .error-icon {
    display: block;
    margin-top: 4px;
  }
  .error-message {
    display: inline-block;
  }
}

.page-header {
  border-bottom: 0;
}

#login-box {
  margin-top: 20px;
  h5 {
    padding-bottom: 0;
  }
  a {
    margin-top: 10px;
    margin-bottom: 5px;
    text-transform: capitalize;
  }
  .panel-body {
    text-align: center;
  }
}

#language-form .form-group {
  margin: 0;
  padding-top: 2px;
  label {
    margin-bottom: 0;
    line-height: 34px;
    vertical-align: middle;
  }
}

.no-gutter {
  padding-left: 0;
  padding-right: 0;
}

body {
  &.lang-ja {
    font-family: 'Noto Sans JP', sans-serif;

    .days-call-out .circle-main {
      padding-top: 30px;
    }

    .data-selections .left-side .underline {
      min-width: 360px;
    }

    #call-out-discount-rate .circle-main {
      font-size: 56px;
      padding-top: 60px;
    }

    .monetary-call-out .monetary-saving {
      padding-bottom: 6px;
    }

    .monetary-call-out .circle-main {
      font-size: 82px;
    }

    .monetary-call-out .circle-main.text-length-1 {
      padding-top: 15px;
      font-size: 100px;
    }

    .monetary-call-out .circle-main.text-length-2 {
      padding-top: 20px;
      font-size: 90px;
    }

    .monetary-call-out .circle-main.text-length-3 {
      padding-top: 30px;
      font-size: 80px;
    }

    .monetary-call-out .circle-main.text-length-4 {
      padding-top: 42px;
      font-size: 66px;
    }

    .monetary-call-out .circle-main.text-length-5 {
      padding-top: 55px;
      font-size: 50px;
    }

    .monetary-call-out .circle-main.text-length-6 {
      padding-top: 60px;
      font-size: 40px;
    }

    .monetary-call-out .circle-main.text-length-7 {
      padding-top: 60px;
      font-size: 38px;
    }

    .monetary-call-out .circle-main.text-length-8 {
      padding-top: 62px;
      font-size: 32px;
    }

    .monetary-call-out .circle-main.text-length-9 {
      padding-top: 64px;
      font-size: 28px;
    }

    .monetary-call-out .circle-main.text-length-10 {
      padding-top: 66px;
      font-size: 26px;
    }
  }

  &.lang-zh {
    font-family: 'Noto Sans SC', sans-serif;

    #call-out-discount-rate .circle-main {
      font-size: 56px;
      padding-top: 60px;
    }

    .monetary-call-out .circle-main {
      font-size: 82px;
    }

    .monetary-call-out .circle-main.text-length-4 {
      font-size: 70px;
    }

    .monetary-call-out .circle-main.text-length-5 {
      font-size: 56px;
    }
  }
}
